import React, { useState, useEffect } from "react"
import styled from "@emotion/styled"
import { injectIntl } from "gatsby-plugin-intl"
import BlankStar from "../../../../static/blank_star.png"
import GreenStar from "../../../../static/green_star.png"
import HalfStar from "../../../../static/half_star.png"

import {
  MobilePrice,
  ProductPriceWrapper,
  ProductPrice,
  ProductAlmaPriceWrapper,
  ProductAlmaPrice,
  ProductAlmaDetail,
} from "../styled"
import { isBrowser } from "../../../context/ApolloContext"
import KlarnaPlacementSection from "../../KlarnaPlacementSection"
import { monthlyPrice } from "../../../utils/navigateToCart"
const $ = isBrowser ? require("jquery") : {}

const ProductTopSectionWrapper = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 40px 0px 10px 0px;

  @media (max-width: 600px) {
    padding: 20px;
  }
`

const TopSection = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
`

const BottomSection = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  padding: 10px 0px 0px;
`

const LeftSection = styled.div`
  width: 100%;
  position: relative;
`

const RightSection = styled.div`
  width: 100%;
  position: relative;
  display: none;

  @media (max-width: 850px) {
    display: flex;
    flex: 1;
  }
`

const ProductSubSection = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
`

const ProductTitleImage = styled.div`
  max-width: 180px;
  margin-right: 10px;
  margin-bottom: 0px;

  @media (max-width: 600px) {
  }

  > img {
    margin: 0px;
  }
`

const ProductReviewRating = styled.div`
  display: flex;
  flex-direction: row;
  font-family: "Gotham HTF Book";
  align-items: center;
  margin-bottom: 5px;

  @media (max-width: 600px) {
    font-size: 13px;
  }
`

const ReviewStar = styled.div`
  display: flex;
  flex-direction: row;
  font-family: "Gotham HTF Book";
  margin: 0px;
  position: relative;
  cursor: pointer;
`

const Image = styled.img`
  height: 100%;
  width: 100%;
  object-fit: contain;
  margin: 0px 1px;
  max-width: 23px;
`
const ProductTitle = styled.h1`
  color: #262626;
  font-size: 16px;
  line-height: 1.2;
  font-family: "Museo";
  font-weight: 400;
  font-style: normal;
  margin-bottom: 0px;
  text-transform: uppercase;

  > span {
    @media (max-width: 600px) {
      display: block;
    }
  }
`

export const LyneHomeProductTopSection = ({
  intl,
  image,
  review_data,
  price,
  title,
  openAlma,
  isMobile,
}) => {
  const totalStars = data => {
    let totalStar = 0
    let avgReview = 0
    for (let i = 0; i < data.length; i++) {
      totalStar = totalStar + Number(data[i].review.stars)
    }
    avgReview = (totalStar / data.length).toFixed(1)
    return avgReview
  }

  const stars = data => {
    let stars = []
    let blank_stars = 5 - data
    for (let i = 1; i <= data; i++) {
      stars.push(
        <Image
          key={"fill" + i.toString()}
          className="lazyload"
          data-src={GreenStar}
          alt=""
        />
      )
    }
    if (blank_stars >= 1) {
      for (let i = 0; i < blank_stars; i++) {
        stars.push(
          <Image
            key={"blank" + i.toString()}
            className="lazyload"
            data-src={BlankStar}
            alt=""
          />
        )
      }
    } else {
      for (let i = 0; i < blank_stars; i++) {
        stars.push(
          <Image
            key={"blank" + i.toString()}
            className="lazyload"
            data-src={HalfStar}
            alt=""
          />
        )
      }
    }
    return stars
  }

  const handleScrolling = () => {
    $("html, body").animate(
      {
        scrollTop: $("#review_section").offset().top - 170,
      },
      1500
    )
  }
  return (
    <ProductTopSectionWrapper id="mobile_below_image">
      <TopSection>
        <LeftSection>
          <ProductSubSection>
            {image && image.data && image.data.url && (
              <ProductTitleImage>
                <img className="lazyload" data-src={image.data.url} />
              </ProductTitleImage>
            )}

            {review_data && (
              <ProductReviewRating>
                <ReviewStar onClick={() => handleScrolling()}>
                  {stars(totalStars(review_data))}
                </ReviewStar>
              </ProductReviewRating>
            )}
          </ProductSubSection>
        </LeftSection>
      </TopSection>
      <BottomSection>
        <ProductTitle
          dangerouslySetInnerHTML={{
            __html: title,
          }}
        />
      </BottomSection>
      <BottomSection
        style={{
          display: isMobile ? "flex" : "none",
          justifyContent: "space-between",
          alignItems: "center",
          width: "calc(100% + 40px)",
          padding: "20px 20px 0px",
          background: "#e8e8e8",
          marginTop: "10px",
        }}
      >
        <LeftSection style={{ width: "auto" }}>
          <MobilePrice>
            <ProductPriceWrapper>
              <ProductPrice>
                {process.env.GATSBY_CURRENCY_TYPE !== "eur"
                  ? process.env.GATSBY_CURRENCY
                  : ""}
                {price}
                {process.env.GATSBY_CURRENCY_TYPE === "eur"
                  ? process.env.GATSBY_CURRENCY
                  : ""}
              </ProductPrice>
            </ProductPriceWrapper>
          </MobilePrice>
        </LeftSection>
        {process.env.GATSBY_CURRENCY_TYPE === "gbp" && (
          <RightSection style={{ maxWidth: "215px" }}>
            <KlarnaPlacementSection
              dataKey="credit-promotion-auto-size"
              price={price}
            />
          </RightSection>
        )}
        {process.env.GATSBY_CURRENCY_TYPE === "eur" && (
          <RightSection
            style={{ maxWidth: "215px", justifyContent: "flex-end" }}
          >
            <ProductAlmaPriceWrapper>
              <ProductAlmaPrice>
                ou 3 x <span>{monthlyPrice(price)}</span>
              </ProductAlmaPrice>
              <ProductAlmaDetail onClick={() => openAlma(true)}>
                {intl.formatMessage({
                  id: "alma_details_text",
                })}
              </ProductAlmaDetail>
            </ProductAlmaPriceWrapper>
          </RightSection>
        )}
      </BottomSection>
    </ProductTopSectionWrapper>
  )
}

export default injectIntl(LyneHomeProductTopSection)
