import React, { Fragment, useState, useEffect, useRef } from "react"
import { graphql } from "gatsby"
import { injectIntl } from "gatsby-plugin-intl"
import { FaArrowRight } from "react-icons/fa"
import { useLocation } from "@reach/router"
import LyneHomeGif from "../../../../static/LyneHomeGif.gif"
import CoqIcon from "../../../../static/Coq.svg"

import {
  ProductInfoSection,
  Container,
  ProductInfoBox,
  ProductImageSection,
  ProductRightInfoSection,
  LyneHomeMobileTopBox,
  ProductSummaryBottom,
  ProductBottomInfo,
  DesktopProductBottomPriceInfo,
  MobileProductBottomPriceInfo,
  ProductBottomCartButtonBox,
  ProductInfoBottom,
  DesktopPrice,
  ProductAlmaPriceWrapper,
  ProductAlmaPrice,
  ProductPriceWrapper,
  ProductPrice,
  ProductPlusDetail,
  GuideSizeDetail,
  ProductAlmaDetail,
  ProductInfoBottomRight,
  ProductAddToCart,
  Select,
  LayerDisabled,
  UserTypeWrapper,
  ChooseGenderText,
  ErrorBox,
  GenderSection,
  GenderSelector,
  GenderSelectorBox,
  GenderSelectorImage,
  GenderSelectorText,
  GenderArrow,
  LyneHomeGuideSizeDetail,
  ProductEstimateShippingBox,
  ProductEstimateShippingIcon,
  ProductEstimateShippingText,
  ChooseSizeSeggestionText,
  ProductStrikePrice,
  ProductDiscountPrice,
  LyneProductStrikePrice,
  LyneProductDiscountPrice,
  LyneComboSection,
  LyneComboWrapper,
  LyneComboBox,
  LyneComboBoxTagWrapper,
  LyneComboInfoIcon,
  LyneComboBoxTag,
  LyneComboBoxTitle,
  LyneComboBottomSection,
  LyneComboLeftSection,
  LyneComboBoxImage,
  LyneCheckImage,
  LyneComboRightBox,
  LyneComboInfoText,
  ClockImage,
  LyneProductRegularPrice,
  SizeRulerImage,
  SizeRulerBox,
} from "../../../components/Products/styled"

import ProductImageSlider from "../../../components/Products/ProductImageSlider"
import ProductUserType from "../../../components/Products/ProductUserType"
import ProductColorBox from "../../../components/Products/ProductColorBox"
import ProductQuantity from "../../../components/Products/ProductQuantity"
import ProductFaqSection from "../../../components/Products/ProductFaqSection"
import Footer from "../../../components/Layout/Footer"
import Testimonials from "../../../components/Layout/Testimonials"
import { withStoreContext } from "../../../context/StoreContext"
import { isBrowser } from "../../../context/ApolloContext"
const $ = isBrowser ? require("jquery") : {}
import PriceDetails from "../../../components/Products/PriceDetails"
import SizeGuidePopup from "../../../components/Products/SizeGuidePopup"

import {
  CapitalizeFirstLetter,
  colorsLyneHome,
  colorsReverseLyneHome,
  gendersLyneHome,
  genderReverseLyneHome,
  imageSliderLyneHome,
} from "../../../utils/lyneHomeHelper"
import BottomStickyAddToCart from "../../../components/Products/BottomStickyAddToCart"
import SeoComp from "../../../components/SeoComp"
import {
  addToCartScript,
  productGtmEvent,
  variantUpdate,
} from "../../../utils/additionalScriptHelpers"
import ProductTestimonialBanner from "../../../components/Products/ProductTestimonialBanner"
import ProductTopBanner from "../../../components/Products/ProductTopBanner"
import LyneHomeActivitySection from "../../../components/Products/LyneHomeActivitySection"
import LyneHomeUtilisationSection from "../../../components/Products/LyneHomeUtilisationSection"
import LyneHomeInnovationSection from "../../../components/Products/LyneHomeInnovationSection"
import LyneHomeActionSection from "../../../components/Products/LyneHomeActionSection"
import LyneHomeMaisonSection from "../../../components/Products/LyneHomeMaisonSection"
import LyneHomeCrossProductSection from "../../../components/Products/LyneHomeCrossProductSection"
import LyneHomeProductTopSection from "../../../components/Products/LyneHomeProductTopSection"
import { cartEstimateDeliveryText } from "../../../utils/cartHelpers"
import {
  getPrice,
  monthlyPrice,
  navigateToCart,
} from "../../../utils/navigateToCart"
import ProductKlarnaSection from "../../../components/Products/ProductKlarnaSection"
import ProductPriceInformationSection from "../../../components/Products/ProductPriceInformationSection"
import TestimonialSection from "../../../components/Layout/TestimonialSection"
import ReactTooltip from "react-tooltip/dist"
import CrossProductSection from "../../../components/Products/CrossProductSection"
import TrustPilotReviewSection from "../../../components/Products/TrustPilotReviewSection"

const LyneHome = ({
  intl,
  data,
  data: { shopifyProduct: product },
  storeContext,
  storeContext: { client, checkout, addVariantToCart },
}) => {
  let defaultOptionValues = {}
  let titleBorderColor = "lynepro"
  product.options.forEach(selector => {
    defaultOptionValues[selector.name] = selector.values[0]
  })
  const [selectedVariant, setselectedVariant] = useState(null)
  const [selectedVariantQuantity, setselectedVariantQuantity] = useState(1)
  const [selectOptions, setselectOptions] = useState({})
  const [openModal, setopenModal] = useState(false)
  const [openAlmaModal, setopenAlmaModal] = useState(false)
  const [openSizeGuideModal, setopenSizeGuideModal] = useState(false)
  const [Errors, setErrors] = useState({ exist: false, message: [] })
  const [activeFlag, setactiveFlag] = useState(false)
  const [discountPrice, setdiscountPrice] = useState(
    process.env.GATSBY_CURRENCY_TYPE === "gbp"
      ? 84
      : process.env.GATSBY_CURRENCY_TYPE === "eur"
      ? 99
      : ""
  )
  const [selectProductType, setselectProductType] = useState("Seul")

  const handleQuantityChange = event => {
    if (event.target.value > 1) {
      setdiscountPrice(
        process.env.GATSBY_CURRENCY_TYPE === "gbp"
          ? 74
          : process.env.GATSBY_CURRENCY_TYPE === "eur"
          ? 89
          : ""
      )
    } else {
      setdiscountPrice(
        process.env.GATSBY_CURRENCY_TYPE === "gbp"
          ? 84
          : process.env.GATSBY_CURRENCY_TYPE === "eur"
          ? 99
          : ""
      )
    }
    setselectedVariantQuantity(event.target.value)
  }

  const handleParamStrings = (selectedOptions, productType) => {
    let paramString = ""
    let currUrl = isBrowser && window.location.pathname
    Object.keys(selectedOptions).map(item => {
      if (paramString.length === 0) {
        if (item === "Gender") {
          paramString = paramString + "?" + item + "=" + selectedOptions[item]
        } else if (item === "Color") {
          paramString = paramString + "?" + item + "=" + selectedOptions[item]
        } else {
          paramString = paramString + "?" + item + "=" + selectedOptions[item]
        }
      } else {
        if (item === "Gender") {
          paramString = paramString + "&" + item + "=" + selectedOptions[item]
        } else if (item === "Color") {
          paramString = paramString + "&" + item + "=" + selectedOptions[item]
        } else {
          paramString = paramString + "&" + item + "=" + selectedOptions[item]
        }
      }
    })
    if (productType) {
      paramString = paramString + "&Offer" + "=" + productType
    }

    // navigate(`${currUrl}${paramString}`)
    isBrowser && history.replaceState(null, null, paramString)
  }

  const removeErros = option => {
    let error = { ...Errors }
    if (error.exist) {
      // if geneder exists
      if (option["Gender"]) {
        let found = error.message.find(
          element =>
            element ===
            intl.formatMessage({
              id: "gender_not_seleceted_error",
            })
        )
        if (found) {
          let index = error.message.indexOf(found)
          error.message.splice(index, 1)
        }
      }
      // if color exists
      if (option["Color"]) {
        let found = error.message.find(
          element =>
            element ===
            intl.formatMessage({
              id: "color_not_seleceted_error",
            })
        )
        if (found) {
          let index = error.message.indexOf(found)
          error.message.splice(index, 1)
        }
      }
      // if size exists
      if (option["Size"]) {
        let found = error.message.find(
          element =>
            element ===
            intl.formatMessage({
              id: "size_not_seleceted_error_lynefit",
            })
        )
        if (found) {
          let index = error.message.indexOf(found)
          error.message.splice(index, 1)
        }
      }
      if (error.message.length === 0) error.exist = false
      setErrors(error)
    }
  }

  const handleChangeSelectOptions = event => {
    const { target } = event
    let selectedOptions = { ...selectOptions }

    if (target.name === "Color") {
      selectedOptions[target.name] = colorsLyneHome[intl.locale][target.value]
    } else if (target.name === "Gender") {
      selectedOptions[target.name] = gendersLyneHome[intl.locale][target.value]
      if (!selectOptions["Color"]) {
        selectedOptions["Color"] = "BLUE"
      }
    } else {
      if (target.value) selectedOptions[target.name] = target.value
    }

    removeErros(selectedOptions)
    handleParamStrings(selectedOptions, selectProductType)
    setselectOptions(selectedOptions)

    if (Object.keys(selectedOptions).length === 3) {
      const selectedVariant = client.product.helpers.variantForOptions(
        product,
        selectedOptions
      )
      setselectedVariant(selectedVariant)
    }
  }

  const handleErrors = () => {
    let error = { ...Errors }
    if (!selectOptions["Gender"]) {
      error.exist = true
      let found = error.message.find(
        element =>
          element === intl.formatMessage({ id: "gender_not_seleceted_error" })
      )
      if (!found)
        error.message = [
          ...error.message,
          intl.formatMessage({ id: "gender_not_seleceted_error" }),
        ]
    }
    if (!selectOptions["Color"]) {
      error.exist = true
      let found = error.message.find(
        element =>
          element === intl.formatMessage({ id: "color_not_seleceted_error" })
      )
      if (!found)
        error.message = [
          ...error.message,
          intl.formatMessage({ id: "color_not_seleceted_error" }),
        ]
    }
    if (!selectOptions["Size"] || selectOptions["Size"].length > 3) {
      error.exist = true
      let found = error.message.find(
        element =>
          element ===
          intl.formatMessage({ id: "size_not_seleceted_error_lynefit" })
      )
      if (!found)
        error.message = [
          ...error.message,
          intl.formatMessage({ id: "size_not_seleceted_error_lynefit" }),
        ]
    }

    removeErros(selectOptions)
    setErrors(error)
  }

  const handleAddTocart = () => {
    if (selectedVariant) {
      addVariantToCart(
        selectedVariant.id,
        selectedVariantQuantity,
        null,
        checkout => {
          addToCartScript(
            product.id,
            product.title,
            selectedVariant,
            selectedVariantQuantity,
            checkout,
            intl.locale
          )
          navigateToCart(intl.locale)
        }
      )
    } else {
      handleErrors()
    }
  }

  const setInitialParamValues = () => {
    const queryString = isBrowser && window.location.search
    const urlParams = new URLSearchParams(queryString)
    const genderParam = urlParams.get("Gender")
    const colorParam = urlParams.get("Color")
    const sizeParam = urlParams.get("Size")
    const productType = urlParams.get("Offer")
    let options = { ...selectOptions }
    if (genderParam) {
      options.Gender = genderParam
      options.Color = "BLUE"
    }

    if (colorParam) {
      options.Color = colorParam
    }
    //   else {
    //     options.Color = "B-GRIS"
    //   }

    if (sizeParam) {
      options.Size = sizeParam
    }

    if (Object.keys(options).length === 3) {
      const selectedVariant = client.product.helpers.variantForOptions(
        product,
        options
      )
      setselectedVariant(selectedVariant)
    }

    if (productType) {
      if (productType === "Pack") {
        setselectedVariantQuantity(2)
      } else {
        setselectedVariantQuantity(1)
      }
      setselectProductType(productType)
    }

    setselectOptions(options)
  }

  const BottomBarActive = () => {
    function isOnScreen(elem) {
      // if the element doesn't exist, abort
      if (elem.length == 0) {
        return
      }
      var $window = $(window)
      var viewport_top = $window.scrollTop()
      var viewport_height = $window.height()
      var viewport_bottom = viewport_top + viewport_height
      var $elem = $(elem)
      var top = $elem.offset().top
      var height = $elem.height()
      var bottom = top + height

      return (
        (top >= viewport_top && top < viewport_bottom) ||
        (bottom > viewport_top && bottom <= viewport_bottom) ||
        (height > viewport_height &&
          top <= viewport_top &&
          bottom >= viewport_bottom)
      )
    }

    $(document).ready(function() {
      window.addEventListener("scroll", function(e) {
        if (!isOnScreen($("#add_to_cart")) && !isOnScreen($("#footer"))) {
          /* Pass element id/class you want to check */
          setactiveFlag(true)
        } else {
          setactiveFlag(false)
        }
      })
    })
  }

  const handleScrolling = () => {
    $("#sticy-button").click(function() {
      var w = window.outerWidth
      if (w <= 600) {
        $("html, body").animate(
          {
            scrollTop: $("#mobile_below_image").offset().top - 150,
          },
          1500
        )
      } else {
        $("html, body").animate(
          {
            scrollTop: $("body").offset().top,
          },
          1500
        )
      }
      handleErrors()
    })
  }

  const handleGender = index => {
    handleChangeSelectOptions({
      target: { name: "Gender", value: index },
    })
  }

  const getDiscountPrice = event => {
    if (selectedVariantQuantity > 1) {
      setdiscountPrice(
        process.env.GATSBY_CURRENCY_TYPE === "gbp"
          ? 74
          : process.env.GATSBY_CURRENCY_TYPE === "eur"
          ? 89
          : ""
      )
    } else {
      setdiscountPrice(
        process.env.GATSBY_CURRENCY_TYPE === "gbp"
          ? 84
          : process.env.GATSBY_CURRENCY_TYPE === "eur"
          ? 99
          : ""
      )
    }
  }

  const handleProductType = productType => {
    let price = ""
    if (productType === "Pack") {
      price = lyneGroupDiscountPrice()
      setselectedVariantQuantity(2)
    } else {
      price = lyneDiscountPrice()
      setselectedVariantQuantity(1)
    }

    let selectedOptions = { ...selectOptions }
    handleParamStrings(selectedOptions, productType)

    // setproductPrice(price)

    productGtmEvent(
      product,
      selectedVariant,
      selectedVariantQuantity,
      intl.locale
    )
    setselectProductType(productType)
  }

  let discountPercentage = 0.2

  const lyneDiscountPrice = () => {
    let lyneDiscountPrice = Number(
      selectedVariant
        ? selectedVariant.price
        : product.priceRangeV2.maxVariantPrice.amount
    )

    if (process.env.GATSBY_CURRENCY_TYPE === "gbp") {
      // lyneDiscountPrice = Number(lyneDiscountPrice) - 15
    } else {
      // lyneDiscountPrice =
      //   Number(lyneDiscountPrice) - Number(lyneDiscountPrice) * Number(discountPercentage)
    }

    return getPrice(lyneDiscountPrice)
  }

  const lyneGroupPrice = () => {
    let lyneGroupPrice = selectedVariant
      ? selectedVariant.price
      : product.priceRangeV2.maxVariantPrice.amount

    lyneGroupPrice = Number(lyneGroupPrice) * 2

    return lyneGroupPrice
  }

  const lyneGroupDiscountPrice = () => {
    let LyneGroupDiscountPrice = lyneGroupPrice()

    if (process.env.GATSBY_CURRENCY_TYPE === "gbp") {
      // LyneGroupDiscountPrice = Number(LyneGroupDiscountPrice) - 50
      discountPercentage = 0.125
      LyneGroupDiscountPrice =
        Number(LyneGroupDiscountPrice) -
        Number(LyneGroupDiscountPrice) * Number(discountPercentage)
    } else {
      LyneGroupDiscountPrice =
        Number(LyneGroupDiscountPrice) -
        Number(LyneGroupDiscountPrice) * Number(discountPercentage)
      // LyneGroupDiscountPrice = Number(LyneGroupDiscountPrice) - 60
    }

    return getPrice(LyneGroupDiscountPrice)
  }

  const mounted = useRef()
  var searchLocation = useLocation()
  useEffect(() => {
    if (!mounted.current) {
      // do componentDidMount logic
      mounted.current = true
      BottomBarActive()
      productGtmEvent(
        product,
        selectedVariant,
        selectedVariantQuantity,
        intl.locale
      )
    } else {
      variantUpdate(
        product,
        selectedVariant,
        selectedVariantQuantity,
        intl.locale
      )
    }
  }, [])

  useEffect(() => {
    getDiscountPrice()
  }, [selectedVariantQuantity])

  useEffect(() => {
    handleScrolling()
  }, [selectOptions])

  useEffect(() => {
    setInitialParamValues()
  }, [searchLocation.search])

  return (
    data &&
    data.allDirectusLyneHomeTranslation.nodes.length > 0 && (
      <Fragment>
        <SeoComp
          description={
            data.allDirectusSeoTranslation.nodes[0] &&
            data.allDirectusSeoTranslation.nodes[0].description
          }
          title={
            data.allDirectusSeoTranslation.nodes[0] &&
            data.allDirectusSeoTranslation.nodes[0].title
          }
          twitter_card={
            data.allDirectusSeoTranslation.nodes[0] &&
            data.allDirectusSeoTranslation.nodes[0].twitter_card
          }
          image={
            data.allDirectusSeoTranslation.nodes[0] &&
            data.allDirectusSeoTranslation.nodes[0].image &&
            data.allDirectusSeoTranslation.nodes[0].image.data &&
            data.allDirectusSeoTranslation.nodes[0].image.data.url
          }
          jsonLd="lynehome"
          hreflangPathname={{
            fr: `shop/lynehome`,
            en: `shop/lynehome`,
            de: `shop/lynehome`,
            es: `shop/lynehome`,
          }}
        />
        <BottomStickyAddToCart
          title={data.allDirectusLyneHomeTranslation.nodes[0].product_title}
          image={
            data.allDirectusLyneHomeTranslation.nodes[0]
              .product_title_logo_image
          }
          gender={genderReverseLyneHome[intl.locale][selectOptions["Gender"]]}
          cart_text={
            !selectOptions["Gender"]
              ? intl.formatMessage({
                  id: "button_text_sticky_banner_no_gender",
                })
              : !selectOptions["Size"]
              ? intl.formatMessage({ id: "button_text_sticky_banner_no_size" })
              : data.allDirectusLyneHomeTranslation.nodes[0]
                  .product_add_to_cart_button
          }
          regularPrice={Number(
            selectedVariant
              ? selectedVariant.price
              : product.priceRangeV2.maxVariantPrice.amount
          ).toFixed(0)}
          handleAddTocart={selectedVariant ? handleAddTocart : null}
          active={activeFlag}
          page="lyneup"
          id="sticy-button"
          quantity={selectedVariantQuantity}
          discountPrice={
            selectedVariantQuantity == 1
              ? lyneDiscountPrice()
              : lyneGroupDiscountPrice()
          }
        />
        <PriceDetails
          details={data.allDirectusLyneHomeTranslation.nodes[0].price_details}
          open={openModal}
          closeHandle={setopenModal}
        />
        <PriceDetails
          details={data.allDirectusLyneHomeTranslation.nodes[0].alma_popup_text}
          open={openAlmaModal}
          closeHandle={setopenAlmaModal}
        />
        <SizeGuidePopup
          sizeChart={data.allDirectusLyneHomeTranslation.nodes[0]}
          gender={selectOptions["Gender"]}
          page="lynehome"
          sizeText={
            data.allDirectusLyneHomeTranslation.nodes[0].size_chart_text
          }
          open={openSizeGuideModal}
          closeHandle={setopenSizeGuideModal}
        />

        <ProductInfoSection id="info-section" page="lyne_home">
          <Container>
            <ProductInfoBox>
              <ProductImageSection>
                {!(selectOptions["Gender"] && selectOptions["Color"]) && (
                  <ProductImageSlider
                    data={imageSliderLyneHome["Default"]}
                    medicaleLogo={
                      data.allDirectusLyneHomeTranslation.nodes[0]
                        .product_innovation_section.medical_image
                    }
                    page="lyne_home"
                    imageSection="couple"
                  />
                )}

                {selectOptions["Gender"] && selectOptions["Color"] && (
                  <ProductImageSlider
                    data={
                      imageSliderLyneHome[selectOptions["Gender"]][
                        selectOptions[["Color"]]
                      ]
                    }
                    medicaleLogo={
                      data.allDirectusLyneHomeTranslation.nodes[0]
                        .product_innovation_section.medical_image
                    }
                    page="lyne_home"
                  />
                )}
              </ProductImageSection>

              <ProductRightInfoSection>
                <LyneHomeProductTopSection
                  image={
                    data.allDirectusLyneHomeTranslation.nodes[0]
                      .product_title_logo_image
                  }
                  price={
                    discountPrice
                      ? Number(99).toFixed(0)
                      : Number(
                          selectedVariant
                            ? selectedVariant.price
                            : product.priceRangeV2.maxVariantPrice.amount
                        ).toFixed(0)
                  }
                  title={
                    data.allDirectusLyneHomeTranslation.nodes[0].product_title
                  }
                  intl={intl}
                  openAlma={setopenAlmaModal}
                />
                <LyneHomeMobileTopBox store={process.env.GATSBY_CURRENCY_TYPE}>
                  {process.env.GATSBY_CURRENCY_TYPE === "eur" &&
                    intl.locale === "fr" && (
                      <MobileProductBottomPriceInfo>
                        <ProductPriceWrapper align="flex-start">
                          <ProductPrice>
                            {`${
                              process.env.GATSBY_CURRENCY_TYPE !== "eur"
                                ? process.env.GATSBY_CURRENCY
                                : ""
                            }${Number(
                              selectedVariant
                                ? selectedVariant.price
                                : product.priceRangeV2.maxVariantPrice.amount
                            ).toFixed(0)}${
                              process.env.GATSBY_CURRENCY_TYPE === "eur"
                                ? process.env.GATSBY_CURRENCY
                                : ""
                            }`}
                          </ProductPrice>
                          {/* <ProductDiscountPrice>
                            {`${
                              process.env.GATSBY_CURRENCY_TYPE !== "eur"
                                ? process.env.GATSBY_CURRENCY
                                : ""
                            }${discountPrice}${
                              process.env.GATSBY_CURRENCY_TYPE === "eur"
                                ? process.env.GATSBY_CURRENCY
                                : ""
                            }`}
                          </ProductDiscountPrice>
                          <ProductStrikePrice>
                            {`${
                              process.env.GATSBY_CURRENCY_TYPE !== "eur"
                                ? process.env.GATSBY_CURRENCY
                                : ""
                            }${Number(
                              selectedVariant
                                ? selectedVariant.price
                                : product.priceRangeV2.maxVariantPrice.amount
                            ).toFixed(0)}${
                              process.env.GATSBY_CURRENCY_TYPE === "eur"
                                ? process.env.GATSBY_CURRENCY
                                : ""
                            }`}
                          </ProductStrikePrice> */}
                          {/* <ProductPlusDetail
                            onClick={() => setopenModal(!openAlmaModal)}
                          >
                            {intl.formatMessage({
                              id: "product_plus_details_text",
                            })}
                          </ProductPlusDetail> */}
                        </ProductPriceWrapper>
                        <ProductAlmaPriceWrapper>
                          <ProductAlmaPrice>
                            ou 3 x{" "}
                            <span>
                              {monthlyPrice(
                                discountPrice
                                  ? 129
                                  : selectedVariant
                                  ? selectedVariant.price
                                  : product.priceRangeV2.maxVariantPrice.amount
                              )}
                            </span>
                          </ProductAlmaPrice>
                          <ProductAlmaDetail
                            onClick={() => setopenAlmaModal(!openAlmaModal)}
                          >
                            {intl.formatMessage({
                              id: "alma_details_text",
                            })}
                          </ProductAlmaDetail>
                        </ProductAlmaPriceWrapper>
                      </MobileProductBottomPriceInfo>
                    )}
                  {process.env.GATSBY_CURRENCY_TYPE === "gbp" && (
                    <MobileProductBottomPriceInfo>
                      <ProductPriceWrapper align="flex-start">
                        <ProductPrice>
                          {`${
                            process.env.GATSBY_CURRENCY_TYPE !== "eur"
                              ? process.env.GATSBY_CURRENCY
                              : ""
                          }${Number(
                            selectedVariant
                              ? selectedVariant.price
                              : product.priceRangeV2.maxVariantPrice.amount
                          ).toFixed(0)}${
                            process.env.GATSBY_CURRENCY_TYPE === "eur"
                              ? process.env.GATSBY_CURRENCY
                              : ""
                          }`}
                          <ProductPriceInformationSection />
                        </ProductPrice>
                        {/* <ProductDiscountPrice>
                          {`${
                            process.env.GATSBY_CURRENCY_TYPE !== "eur"
                              ? process.env.GATSBY_CURRENCY
                              : ""
                          }${discountPrice}${
                            process.env.GATSBY_CURRENCY_TYPE === "eur"
                              ? process.env.GATSBY_CURRENCY
                              : ""
                          }`}
                        </ProductDiscountPrice>
                        <ProductStrikePrice>
                          {`${
                            process.env.GATSBY_CURRENCY_TYPE !== "eur"
                              ? process.env.GATSBY_CURRENCY
                              : ""
                          }${Number(
                            selectedVariant
                              ? selectedVariant.price
                              : product.priceRangeV2.maxVariantPrice.amount
                          ).toFixed(0)}${
                            process.env.GATSBY_CURRENCY_TYPE === "eur"
                              ? process.env.GATSBY_CURRENCY
                              : ""
                          }`}
                          <ProductPriceInformationSection />
                        </ProductStrikePrice> */}
                      </ProductPriceWrapper>
                      {/* <ProductAlmaPriceWrapper style={{ maxWidth: "215px" }}>
                        <KlarnaPlacementSection
                          dataKey="credit-promotion-auto-size"
                          price={Number(
                            selectedVariant
                              ? selectedVariant.price
                              : product.priceRangeV2.maxVariantPrice.amount
                          ).toFixed(0)}
                        />
                      </ProductAlmaPriceWrapper> */}
                    </MobileProductBottomPriceInfo>
                  )}
                  {process.env.GATSBY_CURRENCY_TYPE === "gbp" && (
                    <MobileProductBottomPriceInfo>
                      <ProductKlarnaSection
                        price={Number(
                          selectedVariant
                            ? selectedVariant.price
                            : product.priceRangeV2.maxVariantPrice.amount
                        ).toFixed(0)}
                        page="lyenhome"
                      />
                    </MobileProductBottomPriceInfo>
                  )}
                  <UserTypeWrapper page="lyne_home">
                    {data.allDirectusLyneHomeTranslation.nodes[0]
                      .choose_gender_text && (
                      <ChooseGenderText
                        dangerouslySetInnerHTML={{
                          __html:
                            data.allDirectusLyneHomeTranslation.nodes[0]
                              .choose_gender_text,
                        }}
                      />
                    )}
                    <ProductUserType
                      data={
                        data.allDirectusLyneHomeTranslation.nodes[0].gender.item
                      }
                      handleChange={handleChangeSelectOptions}
                      value={selectOptions["Gender"]}
                      error={selectOptions["Gender"] ? null : Errors.exist}
                      product={"Lyne HOME"}
                      page="lyne_home"
                    />
                  </UserTypeWrapper>
                  <GenderSection
                    style={{
                      flexDirection:
                        selectOptions["Gender"] === "Female"
                          ? "column-reverse"
                          : "column",
                    }}
                    page="lyne_home"
                  >
                    {data.allDirectusLyneHomeTranslation.nodes[0].gender_section.list.map(
                      (item, index) => {
                        return (
                          <GenderSelector
                            key={index.toString()}
                            onClick={() => handleGender(item.value)}
                            active={
                              selectOptions["Gender"] ===
                              gendersLyneHome[intl.locale][item.value]
                            }
                            error={
                              selectOptions["Gender"] ? null : Errors.exist
                            }
                            page="lyne_home"
                          >
                            <GenderSelectorBox>
                              <GenderSelectorImage
                                className="lazyload"
                                data-src={item.image}
                              />
                              <GenderSelectorText
                                dangerouslySetInnerHTML={{
                                  __html: item.text,
                                }}
                              />
                            </GenderSelectorBox>
                            <GenderArrow>
                              <FaArrowRight />
                            </GenderArrow>
                          </GenderSelector>
                        )
                      }
                    )}
                  </GenderSection>

                  <ProductColorBox
                    data={
                      data.allDirectusLyneHomeTranslation.nodes[0].color.item
                    }
                    handleChange={handleChangeSelectOptions}
                    disabled={!(selectOptions && selectOptions["Gender"])}
                    value={selectOptions["Color"]}
                    colors={colorsLyneHome}
                    colorsReverse={colorsReverseLyneHome}
                    formatText={CapitalizeFirstLetter}
                    error={!selectOptions["Color"] && Errors.exist}
                  />
                  <ProductSummaryBottom>
                    {data.allDirectusLyneHomeTranslation.nodes[0]
                      .choose_size_suggestion_text &&
                      selectOptions &&
                      selectOptions["Gender"] && (
                        <ChooseSizeSeggestionText
                          dangerouslySetInnerHTML={{
                            __html:
                              data.allDirectusLyneHomeTranslation.nodes[0]
                                .choose_size_suggestion_text,
                          }}
                        />
                      )}
                    <div className="size_drop_down">
                      {!(selectOptions && selectOptions["Gender"]) && (
                        <LayerDisabled />
                      )}
                      <div className="size_drop_down_child">
                        <Select
                          id="pa_taille_classique"
                          name="Size"
                          onChange={handleChangeSelectOptions}
                          value={selectOptions["Size"]}
                          error={!selectOptions["Size"] && Errors.exist}
                          pageName="lynehome"
                        >
                          <option value="">
                            {intl.formatMessage({
                              id: "my_percko_product_size_text",
                            })}
                          </option>
                          {selectOptions["Gender"] &&
                            data.allDirectusLyneHomeTranslation.nodes[0].size[
                              selectOptions["Gender"]
                            ].map(value => (
                              <option value={value} key={`Size-${value}`}>
                                {value}
                              </option>
                            ))}
                        </Select>
                        {/* <DropIconMobile>
                        <FaAngleDown size={16} />
                      </DropIconMobile>
                      <DropIcon>
                        <FaAngleDown size={16} />
                      </DropIcon> */}
                      </div>
                    </div>
                    {Errors && Errors.exist && (
                      <ErrorBox>
                        {Errors.message.map((item, index) => (
                          <span key={index.toString()}>{item}</span>
                        ))}
                      </ErrorBox>
                    )}
                    <SizeRulerBox>
                      <SizeRulerImage
                        className="lazyload"
                        data-src="https://static.percko.com/uploads/f3c4a6f1-3704-48e1-9668-b19de8889d47.svg"
                      />
                      <GuideSizeDetail
                        onClick={() =>
                          selectOptions &&
                          selectOptions["Gender"] &&
                          setopenSizeGuideModal(!openSizeGuideModal)
                        }
                      >
                        {!(selectOptions && selectOptions["Gender"]) && (
                          <LayerDisabled />
                        )}
                        {
                          data.allDirectusLyneHomeTranslation.nodes[0]
                            .size_chart_text
                        }
                      </GuideSizeDetail>
                    </SizeRulerBox>
                    {((process.env.GATSBY_CURRENCY_TYPE === "eur" &&
                      intl.locale === "fr") ||
                      process.env.GATSBY_CURRENCY_TYPE === "gbp") &&
                      selectOptions["Gender"] &&
                      selectOptions["Color"] &&
                      data.allDirectusLyneHomeTranslation.nodes[0]
                        .combo_section && (
                        <LyneComboSection>
                          <LyneComboWrapper>
                            {data.allDirectusLyneHomeTranslation.nodes[0]
                              .combo_section.single_product_text && (
                              <LyneComboBox
                                onClick={() => handleProductType("Seul")}
                              >
                                {/* {process.env.GATSBY_CURRENCY_TYPE === "gbp" && (
                                  <LyneComboBoxTagWrapper>
                                    <LyneComboBoxTag
                                      dangerouslySetInnerHTML={{
                                        __html:
                                          data.allDirectusLyneHomeTranslation
                                            .nodes[0].combo_section.offer_title,
                                      }}
                                      lang={intl.locale}
                                    />
                                    <LyneComboBoxTag lang={intl.locale}>
                                      OFFER
                                    </LyneComboBoxTag>
                                  </LyneComboBoxTagWrapper>
                                )} */}
                                <LyneComboBoxTitle
                                  dangerouslySetInnerHTML={{
                                    __html:
                                      data.allDirectusLyneHomeTranslation
                                        .nodes[0].combo_section
                                        .single_product_text,
                                  }}
                                />
                                <LyneComboBottomSection>
                                  <LyneComboLeftSection>
                                    {selectOptions["Gender"] == "Female" ? (
                                      <LyneComboBoxImage src="https://static.percko.com/uploads/987e64be-66fc-4961-980b-19eb349dee9c.png" />
                                    ) : (
                                      <LyneComboBoxImage src="https://static.percko.com/uploads/030fa1e9-1622-4c7a-8e69-8709a01c0df8.png" />
                                    )}

                                    <LyneProductRegularPrice>
                                      {`${
                                        process.env.GATSBY_CURRENCY_TYPE !==
                                        "eur"
                                          ? process.env.GATSBY_CURRENCY
                                          : ""
                                      }${Number(
                                        selectedVariant
                                          ? selectedVariant.price
                                          : product.priceRangeV2.maxVariantPrice
                                              .amount
                                      ).toFixed(0)}${
                                        process.env.GATSBY_CURRENCY_TYPE ===
                                        "eur"
                                          ? process.env.GATSBY_CURRENCY
                                          : ""
                                      }`}
                                    </LyneProductRegularPrice>
                                    {/* <LyneProductDiscountPrice>
                                      {`${
                                        process.env.GATSBY_CURRENCY_TYPE !==
                                        "eur"
                                          ? process.env.GATSBY_CURRENCY
                                          : ""
                                      }${lyneDiscountPrice()}${
                                        process.env.GATSBY_CURRENCY_TYPE ===
                                        "eur"
                                          ? process.env.GATSBY_CURRENCY
                                          : ""
                                      }`}
                                    </LyneProductDiscountPrice>
                                    {process.env.GATSBY_CURRENCY_TYPE ===
                                      "gbp" && (
                                      <LyneProductStrikePrice>
                                        {`${
                                          process.env.GATSBY_CURRENCY_TYPE !==
                                          "eur"
                                            ? process.env.GATSBY_CURRENCY
                                            : ""
                                        }${Number(
                                          selectedVariant
                                            ? selectedVariant.price
                                            : product.priceRangeV2
                                                .maxVariantPrice.amount
                                        ).toFixed(0)}${
                                          process.env.GATSBY_CURRENCY_TYPE ===
                                          "eur"
                                            ? process.env.GATSBY_CURRENCY
                                            : ""
                                        }`}
                                      </LyneProductStrikePrice>
                                    )} */}
                                  </LyneComboLeftSection>
                                  <LyneCheckImage
                                    src={
                                      selectProductType === "Seul"
                                        ? "https://static.percko.com/uploads/fd189ad9-7d89-4e1c-b414-751a362b4668.svg"
                                        : "https://static.percko.com/uploads/cc8a1cf0-e49a-4790-a3a7-8bc21ba3f443.svg"
                                    }
                                  />
                                </LyneComboBottomSection>
                              </LyneComboBox>
                            )}
                            {data.allDirectusLyneHomeTranslation.nodes[0]
                              .combo_section.offer_title &&
                              data.allDirectusLyneHomeTranslation.nodes[0]
                                .combo_section.offer_text && (
                                <LyneComboBox
                                  border="0px"
                                  onClick={() => handleProductType("Pack")}
                                >
                                  <LyneComboBoxTagWrapper>
                                    {/* <LyneComboBoxTag
                                      dangerouslySetInnerHTML={{
                                        __html:
                                          data.allDirectusLyneHomeTranslation
                                            .nodes[0].combo_section.offer_title,
                                      }}
                                      lang={intl.locale}
                                    /> */}
                                    {intl.locale === "fr" ? (
                                      <LyneComboBoxTag lang={intl.locale}>
                                        Offres PERCKO
                                        {/* <span>
                                          Derniers
                                          <br />
                                          jours
                                        </span> */}
                                        {/* <ClockImage src={CoqIcon} /> */}
                                      </LyneComboBoxTag>
                                    ) : (
                                      <LyneComboBoxTag lang={intl.locale}>
                                        Sale
                                      </LyneComboBoxTag>
                                    )}
                                    {/* {process.env.GATSBY_CURRENCY_TYPE ===
                                      "eur" && (
                                      <LyneComboInfoText
                                        dangerouslySetInnerHTML={{
                                          __html: "Meilleure offre de l'année ",
                                        }}
                                      />
                                    )} */}
                                    {/* <LyneComboInfoIcon
                                      data-tip={
                                        data.allDirectusLyneHomeTranslation
                                          .nodes[0].combo_tooltip
                                      }
                                      data-html={true}
                                      data-for="lyne_combo_info"
                                    >
                                      i
                                    </LyneComboInfoIcon>
                                    <ReactTooltip
                                      id="lyne_combo_info"
                                      html={true}
                                    /> */}
                                  </LyneComboBoxTagWrapper>
                                  <LyneComboBoxTitle
                                    dangerouslySetInnerHTML={{
                                      __html:
                                        data.allDirectusLyneHomeTranslation
                                          .nodes[0].combo_section.offer_text,
                                    }}
                                  />
                                  <LyneComboBottomSection>
                                    <LyneComboLeftSection>
                                      {selectOptions["Gender"] == "Female" ? (
                                        <LyneComboBoxImage src="https://static.percko.com/uploads/ccae969c-70e4-4357-9d6a-2b440bad9700.png" />
                                      ) : (
                                        <LyneComboBoxImage src="https://static.percko.com/uploads/5c40368e-d60c-432b-a378-a619f4e92112.png" />
                                      )}
                                      <LyneProductDiscountPrice>
                                        {`${
                                          process.env.GATSBY_CURRENCY_TYPE !==
                                          "eur"
                                            ? process.env.GATSBY_CURRENCY
                                            : ""
                                        }${lyneGroupDiscountPrice()}${
                                          process.env.GATSBY_CURRENCY_TYPE ===
                                          "eur"
                                            ? process.env.GATSBY_CURRENCY
                                            : ""
                                        }`}
                                      </LyneProductDiscountPrice>
                                      <LyneProductStrikePrice>
                                        {`${
                                          process.env.GATSBY_CURRENCY_TYPE !==
                                          "eur"
                                            ? process.env.GATSBY_CURRENCY
                                            : ""
                                        }${lyneGroupPrice()}${
                                          process.env.GATSBY_CURRENCY_TYPE ===
                                          "eur"
                                            ? process.env.GATSBY_CURRENCY
                                            : ""
                                        }`}
                                      </LyneProductStrikePrice>
                                    </LyneComboLeftSection>
                                    <LyneCheckImage
                                      src={
                                        selectProductType === "Pack"
                                          ? "https://static.percko.com/uploads/fd189ad9-7d89-4e1c-b414-751a362b4668.svg"
                                          : "https://static.percko.com/uploads/cc8a1cf0-e49a-4790-a3a7-8bc21ba3f443.svg"
                                      }
                                    />
                                  </LyneComboBottomSection>
                                </LyneComboBox>
                              )}
                          </LyneComboWrapper>

                          <LyneComboRightBox
                            dangerouslySetInnerHTML={{
                              __html:
                                data.allDirectusLyneHomeTranslation.nodes[0]
                                  .combo_tooltip,
                            }}
                            lang={intl.locale}
                          />
                        </LyneComboSection>
                      )}
                    {process.env.GATSBY_CURRENCY_TYPE === "eur" &&
                      intl.locale === "fr" && (
                        <ProductBottomInfo>
                          {/* <DesktopProductBottomPriceInfo>
                            <ProductPriceWrapper>
                              <ProductPrice>
                                {`${
                                  process.env.GATSBY_CURRENCY_TYPE !== "eur"
                                    ? process.env.GATSBY_CURRENCY
                                    : ""
                                }${Number(
                                  selectedVariant
                                    ? selectedVariant.price
                                    : product.priceRangeV2.maxVariantPrice
                                        .amount
                                ).toFixed(0)}${
                                  process.env.GATSBY_CURRENCY_TYPE === "eur"
                                    ? process.env.GATSBY_CURRENCY
                                    : ""
                                }`}
                              </ProductPrice> */}
                          {/* <ProductDiscountPrice>
                                {`${
                                  process.env.GATSBY_CURRENCY_TYPE !== "eur"
                                    ? process.env.GATSBY_CURRENCY
                                    : ""
                                }${discountPrice}${
                                  process.env.GATSBY_CURRENCY_TYPE === "eur"
                                    ? process.env.GATSBY_CURRENCY
                                    : ""
                                }`}
                              </ProductDiscountPrice>
                              <ProductStrikePrice>
                                {`${
                                  process.env.GATSBY_CURRENCY_TYPE !== "eur"
                                    ? process.env.GATSBY_CURRENCY
                                    : ""
                                }${Number(
                                  selectedVariant
                                    ? selectedVariant.price
                                    : product.priceRangeV2.maxVariantPrice
                                        .amount
                                ).toFixed(0)}${
                                  process.env.GATSBY_CURRENCY_TYPE === "eur"
                                    ? process.env.GATSBY_CURRENCY
                                    : ""
                                }`}
                              </ProductStrikePrice> */}
                          {/* <ProductPlusDetail
                                onClick={() => setopenModal(!openModal)}
                              >
                                {intl.formatMessage({
                                  id: "product_plus_details_text",
                                })}
                              </ProductPlusDetail> */}
                          {/* </ProductPriceWrapper>
                            <ProductAlmaPriceWrapper>
                              <ProductAlmaPrice>
                                ou 3 x{" "}
                                <span>
                                  {monthlyPrice(
                                    discountPrice
                                      ? 129
                                      : selectedVariant
                                      ? selectedVariant.price
                                      : product.priceRangeV2.maxVariantPrice
                                          .amount
                                  )}
                                </span>
                              </ProductAlmaPrice>
                              <ProductAlmaDetail
                                onClick={() => setopenAlmaModal(!openModal)}
                              >
                                {intl.formatMessage({
                                  id: "alma_details_text",
                                })}
                              </ProductAlmaDetail>
                            </ProductAlmaPriceWrapper>
                          </DesktopProductBottomPriceInfo> */}
                          <ProductBottomCartButtonBox>
                            {/* <ProductQuantity
                              disabled={!selectOptions["Size"]}
                              handleQuantityChange={handleQuantityChange}
                              selectedVariantQuantity={selectedVariantQuantity}
                              mobileDisplay={true}
                            /> */}
                            <div style={{ width: "100%" }}>
                              <ProductAddToCart
                                id="add_to_cart"
                                loading={storeContext.adding ? "adding" : ""}
                                onClick={
                                  selectOptions["Gender"]
                                    ? handleAddTocart
                                    : () => {}
                                }
                                disabled={
                                  selectOptions["Gender"] ? false : true
                                }
                              >
                                {
                                  data.allDirectusLyneHomeTranslation.nodes[0]
                                    .product_add_to_cart_button
                                }
                              </ProductAddToCart>
                              {selectOptions["Size"] && (
                                <ProductEstimateShippingBox>
                                  <ProductEstimateShippingIcon src="https://static.percko.com/uploads/f60c3d29-f9bf-4282-bedd-62a1af4b98a8.svg" />
                                  <ProductEstimateShippingText>
                                    {cartEstimateDeliveryText(
                                      "LyneHome",
                                      data.allDirectusCommonTranslation.nodes[0]
                                        .months_text,
                                      intl.locale,
                                      selectOptions["Size"],
                                      selectOptions["Gender"],
                                      selectOptions["Color"]
                                    )}
                                  </ProductEstimateShippingText>
                                </ProductEstimateShippingBox>
                              )}
                            </div>
                          </ProductBottomCartButtonBox>
                        </ProductBottomInfo>
                      )}
                    {process.env.GATSBY_CURRENCY_TYPE === "gbp" && (
                      <ProductBottomInfo>
                        {/* <DesktopProductBottomPriceInfo>
                          <ProductPriceWrapper>
                            <ProductPrice>
                              {`${
                                process.env.GATSBY_CURRENCY_TYPE !== "eur"
                                  ? process.env.GATSBY_CURRENCY
                                  : ""
                              }${Number(
                                selectedVariant
                                  ? selectedVariant.price
                                  : product.priceRangeV2.maxVariantPrice.amount
                              ).toFixed(0)}${
                                process.env.GATSBY_CURRENCY_TYPE === "eur"
                                  ? process.env.GATSBY_CURRENCY
                                  : ""
                              }`}
                              <ProductPriceInformationSection />
                            </ProductPrice> */}
                        {/* <ProductDiscountPrice>
                              {`${
                                process.env.GATSBY_CURRENCY_TYPE !== "eur"
                                  ? process.env.GATSBY_CURRENCY
                                  : ""
                              }${discountPrice}${
                                process.env.GATSBY_CURRENCY_TYPE === "eur"
                                  ? process.env.GATSBY_CURRENCY
                                  : ""
                              }`}
                            </ProductDiscountPrice>
                            <ProductStrikePrice>
                              {`${
                                process.env.GATSBY_CURRENCY_TYPE !== "eur"
                                  ? process.env.GATSBY_CURRENCY
                                  : ""
                              }${Number(
                                selectedVariant
                                  ? selectedVariant.price
                                  : product.priceRangeV2.maxVariantPrice.amount
                              ).toFixed(0)}${
                                process.env.GATSBY_CURRENCY_TYPE === "eur"
                                  ? process.env.GATSBY_CURRENCY
                                  : ""
                              }`}
                              <ProductPriceInformationSection />
                            </ProductStrikePrice> */}
                        {/* </ProductPriceWrapper>
                          <ProductAlmaPriceWrapper
                            style={{ maxWidth: "215px" }}
                          >
                            <ProductKlarnaSection
                              price={Number(
                                selectedVariant
                                  ? selectedVariant.price
                                  : product.priceRangeV2.maxVariantPrice.amount
                              ).toFixed(0)}
                            />
                          </ProductAlmaPriceWrapper>
                        </DesktopProductBottomPriceInfo> */}
                        <ProductBottomCartButtonBox>
                          {/* <ProductQuantity
                            disabled={!selectOptions["Size"]}
                            handleQuantityChange={handleQuantityChange}
                            selectedVariantQuantity={selectedVariantQuantity}
                            mobileDisplay={true}
                          /> */}
                          <div style={{ width: "100%" }}>
                            <ProductAddToCart
                              id="add_to_cart"
                              loading={storeContext.adding ? "adding" : ""}
                              onClick={
                                selectOptions["Gender"]
                                  ? handleAddTocart
                                  : () => {}
                              }
                              disabled={selectOptions["Gender"] ? false : true}
                            >
                              {
                                data.allDirectusLyneHomeTranslation.nodes[0]
                                  .product_add_to_cart_button
                              }
                            </ProductAddToCart>
                            {selectOptions["Size"] && (
                              <ProductEstimateShippingBox>
                                <ProductEstimateShippingIcon
                                  className="lazyload"
                                  data-src="https://static.percko.com/uploads/f60c3d29-f9bf-4282-bedd-62a1af4b98a8.svg"
                                />
                                <ProductEstimateShippingText>
                                  {cartEstimateDeliveryText(
                                    "LyneHome",
                                    data.allDirectusCommonTranslation.nodes[0]
                                      .months_text,
                                    intl.locale,
                                    selectOptions["Size"],
                                    selectOptions["Gender"],
                                    selectOptions["Color"]
                                  )}
                                </ProductEstimateShippingText>
                              </ProductEstimateShippingBox>
                            )}
                          </div>
                        </ProductBottomCartButtonBox>
                      </ProductBottomInfo>
                    )}
                    {(process.env.GATSBY_CURRENCY_TYPE === "cad" ||
                      process.env.GATSBY_CURRENCY_TYPE === "usd" ||
                      (process.env.GATSBY_CURRENCY_TYPE === "eur" &&
                        intl.locale != "fr")) && (
                      <ProductInfoBottom>
                        <DesktopPrice>
                          <ProductPriceWrapper>
                            <ProductPrice>
                              {process.env.GATSBY_CURRENCY_TYPE !== "eur"
                                ? process.env.GATSBY_CURRENCY
                                : ""}
                              {Number(
                                selectedVariant
                                  ? selectedVariant.price
                                  : product.priceRangeV2.maxVariantPrice.amount
                              ).toFixed(0)}
                              {process.env.GATSBY_CURRENCY_TYPE === "eur"
                                ? process.env.GATSBY_CURRENCY
                                : ""}
                            </ProductPrice>
                            <ProductPlusDetail
                              onClick={() => setopenModal(!openModal)}
                            >
                              {intl.formatMessage({
                                id: "product_plus_details_text",
                              })}
                            </ProductPlusDetail>
                          </ProductPriceWrapper>
                        </DesktopPrice>
                        <ProductInfoBottomRight mobileDisplay={true}>
                          <ProductQuantity
                            disabled={!selectOptions["Size"]}
                            handleQuantityChange={handleQuantityChange}
                            selectedVariantQuantity={selectedVariantQuantity}
                            mobileDisplay={true}
                          />
                          <div style={{ width: "100%" }}>
                            <ProductAddToCart
                              id="add_to_cart"
                              loading={storeContext.adding ? "adding" : ""}
                              onClick={
                                selectOptions["Gender"]
                                  ? handleAddTocart
                                  : () => {}
                              }
                              disabled={selectOptions["Gender"] ? false : true}
                            >
                              {
                                data.allDirectusLyneHomeTranslation.nodes[0]
                                  .product_add_to_cart_button
                              }
                            </ProductAddToCart>
                            {selectOptions["Size"] && (
                              <ProductEstimateShippingBox>
                                <ProductEstimateShippingIcon src="https://static.percko.com/uploads/f60c3d29-f9bf-4282-bedd-62a1af4b98a8.svg" />
                                <ProductEstimateShippingText>
                                  {cartEstimateDeliveryText(
                                    "LyneHome",
                                    data.allDirectusCommonTranslation.nodes[0]
                                      .months_text,
                                    intl.locale,
                                    selectOptions["Size"],
                                    selectOptions["Gender"],
                                    selectOptions["Color"]
                                  )}
                                </ProductEstimateShippingText>
                              </ProductEstimateShippingBox>
                            )}
                          </div>
                        </ProductInfoBottomRight>
                      </ProductInfoBottom>
                    )}
                  </ProductSummaryBottom>
                </LyneHomeMobileTopBox>
              </ProductRightInfoSection>
            </ProductInfoBox>
          </Container>
        </ProductInfoSection>

        {(intl.locale === "fr" || intl.locale === "en") &&
          data.allDirectusCommonTranslation.nodes[0]
            .new_testimonial_section && (
            <TestimonialSection
              data={
                data.allDirectusCommonTranslation.nodes[0]
                  .new_testimonial_section
              }
            />
          )}

        {(intl.locale === "de" || intl.locale === "es") && (
          <ProductTestimonialBanner
            data={
              data.allDirectusLyneHomeTranslation.nodes[0]
                .product_blue_banner_text
            }
            background="#fff"
          />
        )}

        <ProductTopBanner
          data={data.allDirectusLyneHomeTranslation.nodes[0].product_top_banner}
        />

        <LyneHomeActivitySection
          data={
            data.allDirectusLyneHomeTranslation.nodes[0]
              .product_activity_section
          }
        />

        <LyneHomeUtilisationSection
          data={
            data.allDirectusLyneHomeTranslation.nodes[0]
              .product_utilisation_section
          }
          gifImage={LyneHomeGif}
        />

        <LyneHomeInnovationSection
          data={
            data.allDirectusLyneHomeTranslation.nodes[0]
              .product_innovation_section
          }
        />

        <LyneHomeActionSection
          data={
            data.allDirectusLyneHomeTranslation.nodes[0].product_action_section
          }
          background="#C0DBEE"
        />

        <LyneHomeActionSection
          data={
            data.allDirectusLyneHomeTranslation.nodes[0].product_circle_section
          }
        />

        {data.allDirectusReviewTranslation.nodes && (
          <TrustPilotReviewSection
            data={data.allDirectusReviewTranslation.nodes}
          />
        )}

        <LyneHomeMaisonSection
          data={
            data.allDirectusLyneHomeTranslation.nodes[0].product_maison_section
          }
        />

        {process.env.GATSBY_CURRENCY_TYPE === "eur" &&
        intl.locale === "fr" &&
        data.allDirectusLyneHomeTranslation.nodes[0].cross_section ? (
          <CrossProductSection
            data={data.allDirectusLyneHomeTranslation.nodes[0].cross_section}
            price={data.allShopifyProduct.edges}
          />
        ) : (
          <LyneHomeCrossProductSection
            data={
              data.allDirectusLyneHomeTranslation.nodes[0].product_cross_section
            }
            pageName="lyne_home"
          />
        )}

        {data.allDirectusLyneHomeTranslation.nodes[0].product_faq_section && (
          <ProductFaqSection
            borderColor={titleBorderColor}
            data={
              data.allDirectusLyneHomeTranslation.nodes[0].product_faq_section
            }
            pageName="lyne_home"
          />
        )}

        {data.allDirectusCommonTranslation.nodes.length > 0 && (
          <Testimonials
            data={data.allDirectusCommonTranslation.nodes[0].testimonials.list}
          />
        )}
        {data.allDirectusFooterTranslation.nodes.length > 0 && (
          <Footer data={data.allDirectusFooterTranslation.nodes[0]} />
        )}
      </Fragment>
    )
  )
}

export const query = graphql`
  query($lang: String!) {
    site {
      siteMetadata {
        title
      }
    }
    allDirectusLyneHomeTranslation(filter: { language: { eq: $lang } }) {
      nodes {
        product_title
        product_title_logo
        product_new_tag
        product_title_logo_image {
          data {
            url
          }
        }
        product_add_to_cart_button
        product_blue_banner_text {
          list {
            image
            text
          }
        }
        product_top_banner {
          desktop_image
          mobile_image
          logo_image
          tag
          title
          text
        }
        product_activity_section {
          title
          list {
            image
            text
          }
        }
        product_utilisation_section {
          left_image
          title
          text
          list {
            image
            text
          }
        }
        product_innovation_section {
          flag_image
          medical_image
          desktop_image
          mobile_image
          title
          text
        }
        product_action_section {
          title
          text
          desktop_image
          mobile_image
        }
        product_circle_section {
          title
          desktop_image
          mobile_image
        }
        product_maison_section {
          title
          list {
            image
            title
            text
          }
        }
        product_cross_section {
          title
          list {
            image
            title
            logo
            button_text
            button_url
          }
        }
        product_faq_section {
          title
          subtitle
          list {
            question
            answer {
              image
              mobile_image
              text
            }
            open
          }
        }
        size {
          Male
          Female
        }
        color {
          item
        }
        choose_gender_text
        gender {
          item
        }
        price_details
        alma_popup_text
        size_chart_text
        male_desktop_size_chart {
          data {
            url
          }
          localFile {
            childImageSharp {
              gatsbyImageData(width: 550, quality: 100)
            }
          }
        }
        male_mobile_size_chart {
          data {
            url
          }
          localFile {
            childImageSharp {
              gatsbyImageData(width: 550, quality: 100)
            }
          }
        }
        female_desktop_size_chart {
          data {
            url
          }
          localFile {
            childImageSharp {
              gatsbyImageData(width: 550, quality: 100)
            }
          }
        }
        female_mobile_size_chart {
          data {
            url
          }
          localFile {
            childImageSharp {
              gatsbyImageData(width: 550, quality: 100)
            }
          }
        }
        gender_section {
          list {
            image
            text
            value
          }
        }
        size_section {
          male_image
          female_image
          top_title
          top_left_text
          top_right_text
          bottom_title
          bottom_text
          bottom_text_list
        }
        size_popup_text
        choose_size_suggestion_text
        combo_section {
          single_product_text
          offer_title
          offer_text
        }
        combo_tooltip
        cross_section {
          section_title
          name
          image
          alt
          tag
          title
          subtitle
          url
          button_text
          product_list {
            name
            image
            alt
            logo
            logo_alt
            title
            subtitle
            url
          }
        }
      }
    }
    allShopifyProduct(sort: { order: ASC, fields: handle }) {
      edges {
        node {
          id: storefrontId
          handle
          createdAt
          updatedAt
          descriptionHtml
          description
          productType
          title
          vendor
          publishedAt
          options {
            id
            name
            values
          }
          variants {
            id: storefrontId
            title
            price
            weight
            sku
            image {
              id
              src: originalSrc
              #   altText
            }
            selectedOptions {
              name
              value
            }
            price
          }
          images {
            src: originalSrc
            id
          }
          priceRangeV2 {
            minVariantPrice {
              amount
              currencyCode
            }
            maxVariantPrice {
              amount
              currencyCode
            }
          }
        }
      }
    }
    allDirectusReviewTranslation(
      filter: {
        review: { product: { eq: "lynehome" } }
        language: { eq: $lang }
      }
    ) {
      nodes {
        author_name
        review_text
        place_and_date
        review {
          stars
          name
        }
      }
    }
    allDirectusTrustpilotReview {
      nodes {
        language
        name
        review
        status
        stars
        title
      }
    }
    allDirectusFooterTranslation(filter: { language: { eq: $lang } }) {
      nodes {
        facebook
        gbp_facebook
        youtube
        twitter
        instagram
        linkedin
        about_us {
          name
          list {
            link
            list
          }
        }
        contact {
          name
          list {
            link
            list
          }
          button {
            link
            list
          }
        }
        purchases {
          list {
            list
            link
          }
          name
        }
        help {
          list {
            link
            list
          }
          name
        }
        about_us_menu {
          name
          list {
            link
            list
          }
        }
        knowledge_center_menu {
          name
          list {
            link
            list
          }
        }
        shop_menu {
          name
          list {
            link
            list
          }
        }
        support_menu {
          name
          list {
            link
            list
          }
          button {
            link
            list
          }
        }
        newsletter_heading
        follow_us_heading
        footer_bottom {
          list {
            link
            list
          }
        }
      }
    }
    allDirectusCommonTranslation(filter: { language: { eq: $lang } }) {
      nodes {
        testimonials {
          list {
            image
            text
          }
        }
        months_text {
          month
        }
        group_add_to_cart_title
        group_add_to_cart_save_text
        new_testimonial_section {
          title
          subtitle
          list {
            image
            text
            mobile_text
            popup_text
          }
        }
      }
    }
    shopifyProduct(handle: { eq: "lynehome" }) {
      id: storefrontId
      createdAt
      updatedAt
      descriptionHtml
      description
      handle
      productType
      title
      vendor
      publishedAt
      options {
        id
        name
        values
      }
      variants {
        id: storefrontId
        title
        price
        weight
        sku
        compareAtPrice
        image {
          id
          src: originalSrc
          #   altText
        }
        selectedOptions {
          name
          value
        }
        price
      }
      images {
        src: originalSrc
        id
      }
      priceRangeV2 {
        maxVariantPrice {
          amount
          currencyCode
        }
      }
    }
    allDirectusSeoTranslation(
      filter: {
        language: { eq: $lang }
        seo: { page_name: { eq: "lynehome" } }
      }
    ) {
      nodes {
        description
        title
        twitter_card
        twitter_title
        url
        image {
          data {
            url
          }
        }
      }
    }
  }
`

export default injectIntl(withStoreContext(LyneHome))
