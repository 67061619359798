import React, { Fragment } from "react"
import styled from "@emotion/styled"
import { injectIntl } from "gatsby-plugin-intl"

const ProductTestimonialBannerWrapper = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  background: ${({ background }) => (background ? background : "#fff")};
`

const Container = styled.div`
  display: flex;
  max-width: 1080px;
  width: 100%;
  margin: 0px auto;
  padding: 15px 20px;
  justify-content: space-around;

  @media (max-width: 550px) {
    justify-content: space-between;
  }

  @media (max-width: 440px) {
    flex-wrap: wrap;
    padding: 5px 15px;
  }
`

const TestimonialBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  object-fit: contain;
  flex-direction: row;

  @media (max-width: 600px) {
    flex-direction: column;
  }

  @media (max-width: 440px) {
    padding: 10px 5px;
  }
`

const TestimonialImage = styled.img`
  object-fit: contain;
  margin-bottom: 0;
  width: 50px;
`

const TestimonialText = styled.div`
  justify-content: center;
  align-items: center;
  margin-left: 15px;
  font-size: 14px;
  line-height: 18px;
  font-weight: bold;

  @media (max-width: 600px) {
    margin-left: 0px;
    margin-top: 5px;
    text-align: center;
  }
`

export const ProductTestimonialBanner = ({ intl, data, background, color }) => (
  <ProductTestimonialBannerWrapper background={background}>
    {data && (
      <Container>
        {data.list.map((item, index) => {
          return (
            <TestimonialBox key={index.toString()}>
              {item.image && (
                <TestimonialImage className="lazyload" data-src={item.image} />
              )}
              {item.text && (
                <TestimonialText
                  dangerouslySetInnerHTML={{
                    __html: item.text,
                  }}
                />
              )}
            </TestimonialBox>
          )
        })}
      </Container>
    )}
  </ProductTestimonialBannerWrapper>
)

export default injectIntl(ProductTestimonialBanner)
